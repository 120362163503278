<template>
  <div class="login" v-cloak>
    <div class="logo">
      <img src="../../assets/lblogo.png" alt="">
    </div>
    <div class='tishi'>
      <img src="../../assets/lbyhqts.png" alt="">
    </div>
    <div class='time'>
      <p>活动时间：2022.1.26 10:00:00-2022.3.31 23:59:59</p>
    </div>
    <!-- <div class='youhuiquan' @click='fenxiang'>
      <img src="../../assets/lblqyhq.png" alt="">
    </div> -->
    <div class='lqyouhuiquan'>
       <img src="../../assets/yhqme.png" alt="">
       <div class='btn' @click='fenxiang'>
         立即助力
       </div>
       <div class='zhuli'>
         好友助力成功可得满9.9减8.9元优惠券
       </div>
    </div>
    <div class='lqbz'>
       <div class='tb'> 
         <img src="../../assets/lbbz1.png" alt="">
         <p>点击我要领取 分享给3个不同的好友</p>
       </div>
       <div class='tb'> 
         <img src="../../assets/lbbz2.png" alt="">
         <p>好友通过你分享的页面 通过注册后点击立即助力</p>
       </div>
       <div class='tb'> 
         <img src="../../assets/lbbz3.png" alt="">
         <p>完成助力任务 即可获得大额优惠券</p>
       </div>
    </div>
    <div class="friend">
      <div class="title">助力好友</div>
      <p>邀请好友参加助力！</p>
      <div class="photo">
        <div v-for="item in list">
          <img :src="item.member.avatar" alt="">
          <p>{{item.member.nickname}}</p>
        </div>
        <div v-if='this.list==""' @click='login'>
          <img src="../../assets/lbbr.png" alt="">
          <p>用户名</p>
        </div>
        <div v-if='this.list.length<=1' @click='login'>
          <img src="../../assets/lbyq.png" alt="">
          <p>用户名</p>
        </div>
        <div  v-if='this.list.length<=2' @click='login'>
          <img src="../../assets/lbyq.png" alt="">
          <p>邀请更多好友...</p>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="erweima">
        <!-- <div>
          <img src="../../assets/lbkf.png" alt="">
          <p>考研通客服二维码</p>
        </div> -->
        <div>
          <img src="../../assets/lbxzewm.png" alt="">
          <p>下载考研通APP</p>
        </div>
      </div>
      <div class='bot'>
        更多详情可联系考研通客服 <br>
        以上最终解释权归考研通所有
      </div>
    </div>
    <div class="guize"  @click='open(0)'>
      活动规则
    </div>
    <div class='guizebox' v-if='this.hidden==true' >
    <div class="guizetext">
      <div class="img" @click='open(1)'>
        <img src="../../assets/lbguanbibtn.png" alt="">
      </div>
      <div class="title">
        活动规则
      </div>
      <div class="text">
        1. 参与资格：每一位在考研通APP完成注册的用户都可以参与本次活动；<br>
        2. 领取方式：活动期间用户通过直接点击页面中的分享按钮，将活动页面分享至好友邀请其注册；如成功分享3人，即可领取大额主券；<br>
        3. 同一个账户、同一个手机号、同一个设备号、同一个支付账号均视为同一用户（适用于您与您的好友） <br>
        4. 领取次数：每一位用户至多只能领取主券1张，子券3张；<br> 
        5.优惠券的发放可能稍有延迟，会将尽快发放到您的账户中; <br> 
        6.优惠券的数量有限，先到先得；<br> 
        7.若用户存在刷奖等恶意行为，一经发现将取消抽奖资格（如奖品已经发放，有权追回奖品）；<br> 
        8.优惠券使用范围：仅可用于指定课程；
      </div>
      <div class="titlet">
        【注意事项】
      </div>
      <div class="textt">
        （1）活动过程中，凡以不正当手段（如作弊领取、恶意套取、刷信誉、虚假交易、扰乱系统、实施网络攻击等）参与本次活动的用户，商家有权终止其参与活动，并取消其参与资格（如优惠已发放，商家有权追回），如给商家造成损失的，商家将保留向违规用户继续追索的权利。<br> 
        （2）如遇不可抗力(包括但不限于重大灾害事件、活动受政府机关指令需要停止举办或调整的、活动中存在大面积作弊行为、活动遭受严重网络攻击或因系统故障导致活动中奖名单大批量出错，活动不能正常进行的)，商家有权取消、修改或暂停本活动。 <br>
        （3）是否获得优惠以活动发布者后台统计结果为准。
      </div>
    </div>
    </div>
  </div>
</template>
<script>
import Cookies from 'js-cookie';
import { Toast } from 'vant';
import request from "../../plugin/request";
export default {
    data() {
    return {
      hidden:false,
       token:'',
       id:'',
       list:'',
       idtow:'',
       share_id:'',
       sid:'',
       eid:''
    };
  },
    mounted(){
    let currentQuery = this.$route.query;
    this.id=currentQuery.id;
    this.sid=currentQuery.sid;
    this.eid=currentQuery.eid;
    this.token=currentQuery.token;
    this.setToken();
    this.$nextTick(() => {
      this.details()
    });
  },
  methods:{
    open(status) {
       if(status==0) {
         this.hidden=true
       } else if(status==1) {
         this.hidden=false
       }
    },
    fenxiang() {
      if(this.token==undefined) {
        Toast('下载考研通APP发起活动进行分享！')
        this.$router.push({ path:'/download'})
      } else {
        Toast('点击右上角分享按钮立即助力！')
      }
    },
    setToken() {
       var tokens = localStorage.getItem('token');
       if(this.token==undefined) {
         return Cookies.set('Token', tokens)
       }else {
         return Cookies.set('Token', this.token)
       }
      
    },
    async details() {
      const {data} = await request.post('/app/couponEvent/details',{ id:this.id,sid:this.sid,edi:this.eid})
      console.log(data.user)
      this.idtow=data.event.id
      this.share_id=data.share_id
      this.list=data.user
    },
    login () {
      var tokens = localStorage.getItem('token');
      console.log(this.token,tokens)
      if(this.token==undefined&&tokens==null) {
         this.$router.push({ path:'/login?url=fission&id='+this.id})
      } else {
         this.zhuli()
      }
    },
    async zhuli() {
      const data=await request.post('/app/couponEvent/receiveChild',{ id:this.idtow,share_id:this.share_id})
      console.log(data)
      if(data.code==200) {
        Toast('您已助力成功！')
        this.details()
      } else if(data.code==200) {
        Toast(data.message)
      }
      
    }
  }
};
</script>
<style lang="less" scoped>
.login {
  width:  100%;
  background-color: antiquewhite;
  background: url(../../assets/lbbj.png);
  background-size: 100%;
  text-align: center;
  padding-bottom: .2rem;
  padding-top: .37rem;
  position: relative;

  .logo {
     width: 2.17rem;
     height: .76rem;
     margin-left: .53rem;

     img {
       width: 100%;
       height: 100%;
     }
  }

  .tishi {
    width: 8.11rem;
    height: 3.09rem;
    margin-top: .75rem;
    margin-left: 50%;
    transform: translateX(-50%);


    img {
      width: 100%;
      height: 100%;
    }
  }

  .time {
    color: #fff;
    font-size: .35rem;
    margin-top: .49rem;
    p {
      padding:0;
      margin:0;

    }
  }

  .youhuiquan {
    width: 9.97rem;
    height: 3.59rem;
    margin-top: .13rem;
    margin-left: 50%;
    transform: translateX(-50%);

    img {
      width: 100%;
      height: 100%;
    }
  }

  .lqyouhuiquan {
    width: 9rem;
    height: 9.89rem;
    background-color: #FFF3DB;
    margin-left: 50%;
    transform: translateX(-50%);
    margin-top: .16rem;

    img {
      width: 5.93rem;
      height: 6.25rem;
      margin-top: 23px;
      margin-left: 50%;
      transform: translateX(-50%);
    }

    .btn {
      width: 6.31rem;
      height: 1.15rem;
      border-radius: .57rem;
      background-color: #F8CB80;
      text-align: center;
      color: #80240C;
      font-size: .53rem;
      line-height: 1.15rem;
      margin-left: 50%;
      transform: translateX(-50%);
      margin-top: .27rem;
      font-weight: bold;

    }

    .zhuli {
      width: 9rem;
      height: 9.89rem;
      margin-left: 50%;
      transform: translateX(-50%);
      margin-top: .53rem;
      color: #EC434A;
      font-size: .35rem;
      font-weight: bold;
    }
  }

  .lqbz {
    width: 9rem;
    height: 4.45rem;
    margin-left: 50%;
    transform: translateX(-50%);
    background: url(../../assets/lbbiankuang.png);
    background-size: contain;
    margin-top: .53rem;
    padding-top: 1.05rem;
    padding-bottom: .85rem;
    box-sizing: border-box;
    display: flex;
    justify-content:space-evenly;

    .tb {
      width: 2.49rem;
      display: inline-block;
      

      img {
        width: 1.77rem;
        height: 1.69rem;
      }

      p  {
        padding:0;
        margin:0;
        font-size:.27rem;
        color: #fff;
      }
    }
  }

  .friend {
    width: 9.2rem;
    margin-top: .85rem;
    margin-left: 50%;
    transform: translateX(-50%);
 
    .title {
      font-size: .48rem;
      font-weight: 500;
      color: #FFFFFF;
      margin-bottom: .52rem;
    }

    p  {
      padding:0;
      margin:0;
      font-size: .32rem;
      color: #fff;
    }

    .photo {
      width: 100%;
      display: flex;
      justify-content:space-evenly;
      margin-top: .43rem;
      img {
       width: 1.55rem;
       height: 1.55rem;
       margin-right: 50%;
       border-radius: 50%;
       overflow: hidden;
       margin-left:50%;
       transform: translateX(-50%);
       
      }
      p {
        font-size: .32rem;
        color: #fff;
      }
    }
  }

  .bottom {
    width: 100%;
    margin-top: .79rem;

    .erweima {
      width: 100%;
      display: flex;
      justify-content:space-evenly;
      img  {
        width: 3.23rem;
        height: 3.23rem;
      }

      p {
        font-size: .35rem;
        color: #fff;
        padding:0;
        margin:0;
      }
    }

    .bot {
      font-size: .27rem;
      color: #fff;
      margin-top:.69rem;
    }
  }

  .guize {
    width: 2.03rem;
    height: .75rem;
    position:absolute;
    top:.44rem;
    right: 0;
    background-color: #FEE38A;
    border-radius: .37rem 0 0 .37rem;
    font-size: .4rem;
    line-height: .75rem;
    color: #F15652;
    font-weight:bold;
    text-align: center;
  }
.guizebox {
  width: 100%;
  height: 100%;
  position: absolute;
  top:0;
  left: 0;
  background-color: rgba(0,0,0,0.6);
  .guizetext {
    width: 7.52rem;
    background-color:#FAE9AF;
    position: relative;
    top: 2.01rem;
    left: 50%;
    transform: translateX(-50%);
    border-radius: .13rem;
    padding:.51rem .4rem;
    color: #F15755;
    .img {
      width: .37rem;
      height: .37rem;
      position: absolute;
      top: .27rem;
      right: .27rem;
      img { 
        width: 100%;
        height: 100%;
      }
    }
    .title {
      font-size: .4rem;
      font-weight: bold;
    }

    .text {
      text-align: left;
      font-size: .32rem;
      margin-top: .49rem;
    }

    .titlet {
      margin-top: .45rem;
      font-size: .32rem;
      font-weight: 500;
    }

    .textt {
      font-size: .32rem;
      text-align: left;
      margin-top: .36rem;
    }

  }

}

}
</style>
